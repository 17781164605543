<template>
  <div>
   <div class="bg-white p-4 text-gray-500  my-4 rounded-lg shadow-sm"  v-for="(element) in descriptionAndTitle" :key="element.id" >
      <span v-if="element.id"  class="py-3 text-gray-800">
        Content Recommendation <i class="fa fa-angle-right" aria-hidden="true"></i> {{element.title}}
        <h5 class="mt-1 text-2xl">{{element.title}}</h5>
      </span>
    </div>

    <div class="flex flex-wrap justify-between px-4 pt-4 rounded-lg items-center bg-white shadow-sm">
      <div class="flex w-full justify-between items-center">
        <h5 class="mt-2 mb-2 flex text-xl w-full text-left"> Content Recommendation Details</h5>
        <button class="bg-blue-500 text-white px-2 py-1 rounded-lg font-semibold">
          <div class="flex justify-between align-middle items-center">
            <svg class="w-4 h-4 mr-1 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd"
                d="M11.32 6.176H5c-1.105 0-2 .949-2 2.118v10.588C3 20.052 3.895 21 5 21h11c1.105 0 2-.948 2-2.118v-7.75l-3.914 4.144A2.46 2.46 0 0 1 12.81 16l-2.681.568c-1.75.37-3.292-1.263-2.942-3.115l.536-2.839c.097-.512.335-.983.684-1.352l2.914-3.086Z"
                clip-rule="evenodd" />
              <path fill-rule="evenodd"
                d="M19.846 4.318a2.148 2.148 0 0 0-.437-.692 2.014 2.014 0 0 0-.654-.463 1.92 1.92 0 0 0-1.544 0 2.014 2.014 0 0 0-.654.463l-.546.578 2.852 3.02.546-.579a2.14 2.14 0 0 0 .437-.692 2.244 2.244 0 0 0 0-1.635ZM17.45 8.721 14.597 5.7 9.82 10.76a.54.54 0 0 0-.137.27l-.536 2.84c-.07.37.239.696.588.622l2.682-.567a.492.492 0 0 0 .255-.145l4.778-5.06Z"
                clip-rule="evenodd" />
            </svg>

            <span>
              <router-link class="text-white" :to="{ path: `/content-recommedation/edit/${this.$route.params.id}`}">
                Edit
              </router-link>
            </span>
          </div>

        </button>
      </div>

      <div class="flex w-full"
        :class="['transition-max-height duration-300 ease-in-out overflow-hidden', readMore ? 'min-h-max' : 'h-36']"  v-for="(element) in descriptionAndTitle" :key="element.id">

        <div class="w-1/2 flex flex-col mr-2" >
          <span class="font-bold mb-1">Section title</span>
          <p class="mb-1 text-gray-500">{{element.title}}</p>
          <label class="font-bold">Description</label>
          <p class="text-gray-500">
            {{element.description ?? '-'}}
          </p>
        </div>
        <div class="w-1/2 ml-2 flex flex-col">
          <p class="mb-1 font-bold">Created Date</p>
          <span class="text-gray-500">  {{formatDate(element.created) ?? '-'}}</span>
          <p class="mb-1 mt-2 font-bold">Last Modified Date</p>
          <span class="text-gray-500">  {{formatDate(element.modified) ?? '-'}}</span>
        </div>
      </div>
      <div class="flex w-full text-center justify-center pb-4 pt-2">
        <div class="text-gray-700 flex items-center font-bold text-sm cursor-pointer" @click="toggleReadMore">
          <span>
            {{ readMore ? 'Read less' : 'Read more' }}
          </span>
          <svg v-if="readMore" class="w-4 h-4 ml-1 text-gray-800 dark:text-white" aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m19 9-7 7-7-7" />
          </svg>
          <svg v-else class="w-4 h-4 ml-1 text-gray-800 dark:text-white" aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m5 15 7-7 7 7" />
          </svg>
        </div>
      </div>
    </div>

    <div class="mt-4 pt-2 pb-2 rounded-lg bg-white shadow-sm">
      <Toast  />
      <ModalDeleteContent :isModalVisibleDelete.sync="showModalDelete"
      :isOpenDelete="showModalDelete" @close="closeModal" @confirm="confirmDelete" />
      <div class="flex justify-between items-center my-4 px-4">
        
        <div class="w-1/2 px-1">
          <label class="font-bold text-xl text-gray-700 ">All Contents Under this Section Title</label>
       
        </div>
        <div class="w-1/2 px-3 flex justify-end">
          <button  v-if="!triggerSort" @click="triggerSortBtn"
          class=" rounded-lg border-gray-200 p-2 border  focus:outline-none relative right text-gray-500 bg-gray-white">
          <i class="fa fa-list" aria-hidden="true"></i>
          Reorder Content
        </button>
        
        <button v-if="triggerSort" class="rounded-lg border p-2 focus:outline-none relative text-white bg-blue-500" @click="submitAndSave">Save & Update </button>
        <button v-if="triggerSort" class="rounded-lg ml-3 p-2 focus:outline-none relative text-gray-500 bg-white border" @click="handleCancelSort">Cancel</button>
    
        </div>
        </div>
      <div v-if="isLoading" class="mt-5 flex justify-center">
        <div class="text-center text-dark my-2 w-full bg-white"
          style="height: 600px; background-color:rgb(204 204 204 / 39%); display: flex; border-radius: 5px; justify-content: center; align-items: center;">
          <div style="transform: translate(-50%, -50%);">
            <b-spinner :show='isLoading' class="align-middle" variant="dark" type="grow" label="Loading .."></b-spinner>
            <span v-if="isLoading">Loading ..</span>
          </div>
        </div>
      </div>
      <div class="px-4 mb-4 relative rounded-lg " v-else>
        <div class="relative parent-table  overflow-x-auto shadow-md sm:rounded-lg scrollbar parent-table" id="style-1" >
          <table
            class="w-full h-[400px] border-r border-l text-sm text-gray-500 text-left rtl:text-right bg-white dark:text-gray-400 drag-move rounded-lg"
            v-show="!isLoading">
            <thead>
              <tr>
                <th scope="col" class="px-6 py-3"></th>
                <th scope="col" class="px-6 py-3">No</th>
                <th scope="col" class="px-6 py-3">Item Type</th>
                <th scope="col" class="px-6 py-3">Item Name</th>
                <th scope="col" class="px-6 py-3">Items Id</th>
                <th></th>
              </tr>
            </thead>
            <draggable v-if="items.length > 0" :class="triggerSort ? 'cursor-move showBtn' : 'pointer-events-none'" element="tbody"
              :items="items" :move="checkMove" v-bind="dragOptions" :disabled="!enabled" ghost-class="ghost"
              @start="handleDragStart"
              @end="handleDragEnd($event)"
              >
              <tr v-for="(element, index) in items" :key="element.id" :data-item-id="element.item_id">
                <td scope="col" class="px-6 py-3">
                  <span :class="{ 'block': triggerSort, 'hidden': !triggerSort }">
                    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.879883 4.44001C0.879883 4.08654 1.16642 3.80001 1.51988 3.80001H10.4799C10.8333 3.80001 11.1199 4.08654 11.1199 4.44001C11.1199 4.79347 10.8333 5.08001 10.4799 5.08001H1.51988C1.16642 5.08001 0.879883 4.79347 0.879883 4.44001ZM0.879883 7.00001C0.879883 6.64654 1.16642 6.36001 1.51988 6.36001H10.4799C10.8333 6.36001 11.1199 6.64654 11.1199 7.00001C11.1199 7.35347 10.8333 7.64001 10.4799 7.64001H1.51988C1.16642 7.64001 0.879883 7.35347 0.879883 7.00001ZM0.879883 9.56001C0.879883 9.20654 1.16642 8.92001 1.51988 8.92001H10.4799C10.8333 8.92001 11.1199 9.20654 11.1199 9.56001C11.1199 9.91347 10.8333 10.2 10.4799 10.2H1.51988C1.16642 10.2 0.879883 9.91347 0.879883 9.56001Z" fill="#6B7280"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.74981 0.716474C5.9051 0.561184 6.15688 0.561184 6.31217 0.716474L7.43687 1.84118C7.59216 1.99647 7.59216 2.24825 7.43687 2.40354C7.28158 2.55883 7.02981 2.55883 6.87452 2.40354L6.03099 1.56001L5.18746 2.40354C5.03217 2.55883 4.78039 2.55883 4.6251 2.40354C4.46981 2.24825 4.46981 1.99647 4.6251 1.84118L5.74981 0.716474Z" fill="#6B7280"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.31246 13.2835C6.15717 13.4388 5.90539 13.4388 5.7501 13.2835L4.62539 12.1588C4.4701 12.0035 4.4701 11.7518 4.62539 11.5965C4.78068 11.4412 5.03246 11.4412 5.18775 11.5965L6.03128 12.44L6.87481 11.5965C7.0301 11.4412 7.28188 11.4412 7.43717 11.5965C7.59246 11.7518 7.59246 12.0035 7.43717 12.1588L6.31246 13.2835Z" fill="#6B7280"/>
                  </svg>
                  </span>
                </td>
                <td scope="col" class="px-6 py-3">{{ index + 1 }}</td>
                <td scope="col" class="px-6 py-3">{{ element.item_type }}</td>
                <td scope="col" class="px-6 py-3">{{ element.item_name }}</td>
                <td scope="col" class="px-6 py-3">{{ element.item_id }}</td>
                <td>
                  <span :class="{ 'block': triggerSort, 'hidden': !triggerSort }" @click="removeItem(element, index)">
                    <i class="fa fa-trash text-gray-500 cursor-pointer" aria-hidden="true" style="font-size:20px;"></i>
                  </span>
                </td>
              </tr>
            </draggable>
            <tbody v-else>
                      <tr>
                          <td colspan="6" class="text-center">No items available</td>
                      </tr>
              </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import draggable from "vuedraggable";
import { format } from 'date-fns';
import Toast from './component/Toast.vue';
import { EventBus } from '../../../src/event-bus';
import ModalDeleteContent from './component/ModalDeleteContent.vue'
export default {
  name: 'recommendationhomepagedetail',
  components: {
    draggable,
    ModalDeleteContent,
    Toast
  },
  props: {
    category: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {

      itemId: parseInt(this.$route.params.id),
      contentTypeOptions: ['book', 'magazine', 'newspaper'],
      selectedOption: '',
      itemsFieldName: [],
      isNameSearch: false,
      dragging: false,
      enabled: true,
      resetFetchbtn: false,
      dataTable: [],
      offerIdExists: false,
      optionsSearchFieldName: [],
      optionsFieldName: [],
      moveDrag: [],
      addAllItem: [],
      draggedItem: null,
      readMore: false,
      triggerSort: false,
      toastVisible: false,
      toastMessage: '',
      toastType: 'info',
      itemToDelete: null,
      itemIndex: null,
      showModalDelete: false,
      listDeleteTable: []
    };

  },
  watch: {
    errorMessage: function () {
      if (!this.errorMessage) return;
      this.messageAlert('error', this.errorMessage);
    },
    successMessage: function () {
      if (!this.successMessage) return;
      this.alertMsg(this.successMessage, 'success');
    
    },
  },
  computed: {
    ...mapState({
      items: (state) => state.content.items,
      descriptionAndTitle:(state) =>state.content.descriptionAndTitle, 
      successMessage: (state) => state.content.successMessage,
      alertMessage:(state) => state.content.successMessage,
      alertType:(state) => state.content.alertType,
      errorMessage: (state) => state.content.errorMessage,
      isLoading: (state) => state.content.isLoading,
    }),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        //     draggedIndex: null,
        ghostClass: "ghost",
      };
    }

  },
  created() {
    this.detailRecomendationId(this.itemId)
    this.detailRecomendationTitleDescription(this.itemId)
  },
  mounted() {
  },
  methods: {
    ...mapActions("content", ["fetchCategoriRecomendation","detailRecomendationId","detailRecomendationTitleDescription","getSearchOffer", "deleteItems", "addItemsField", "allUpdateData","allSaveAndSubmit", "moveUptable"]),
    ...mapMutations('content', ['deleteItem', 'addArrayItems', 'SET_SORT_PRIORITY', 'SET_ITEMS']),
    
    formatDate(date) {
      if (!date) return '';
      return format(new Date(date), 'dd MMM yyyy, HH:mm:ss');
    },
    
    removeItem(data, index) {
            this.itemToDelete = data;
            this.itemIndex = index;
            this.showModalDelete = true;
        },

        closeModal() {
            this.showModalDelete = false
        },
        confirmDelete() {
            if (this.itemToDelete && this.itemIndex !== null) {
                this.deleteItem(this.itemIndex);
                this.closeModal();
            }
        },
    triggerSortBtn() {
      this.triggerSort = !this.triggerSort
    },
    handleCancelSort() {
      this.triggerSort = false
      this.detailRecomendationId(this.itemId)
    },
    checkMove(e) {
      console.log("Future index: " + e.draggedContext.futureIndex);
    },
    handleDragStart(event) {
      const item_id = parseInt(event.item.dataset.itemId, 10);
      this.draggedItem = this.items.find(item => item.item_id === item_id);

    },
    toggleReadMore() {
      this.readMore = !this.readMore
    },
    handleDragEnd(event) {
      const newIndex = event.newIndex;
      const item_id = parseInt(event.item.dataset.itemId, 10);
      const datadrag = this.items.find(item => item.item_id === item_id);
      this.SET_SORT_PRIORITY({ datadrag, newIndex });
    },

    async submitAndSave(){
      const payload = {
        id:this.itemId,
        items:this.items
      }
      await this.allSaveAndSubmit(payload).then(()=> {
        EventBus.$emit('show-toast', {
                    message: 'Update has been success',
                    type: 'success',
                });
      })
    },
    handleCancel() {
      this.optionsSearchFieldName = []
      this.itemsFieldName = []
    }
  }
};
</script>
<style>
.custom-arrow {
  cursor: pointer;
}

.recomendation_hompage_cms {
  background-color: #fff;
}

.custom-arrow.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: default;
}

.table.custom.drag-move {
  background-color: #ffffff;
}

table tr {
  background: #ffffff;
}

.table.custom.drag-move thead tr th {
  height: 50px;
}

.list-group {
  background-color: #ffffff;
}

.parent-table {
  max-height: 600px;
  overflow-y: auto;
}

.drag-move th,
.drag-move td {
  padding: 8px 12px;
  text-align: left;
  font-size: 15px;
  vertical-align: middle !important;
  border-bottom: 1px solid #ddd;
}

.drag-move th {
  position: sticky;
  top: 0;
  background-color: #c2cfd6;
  z-index: 2;
}

.scrollbar {
  overflow-y: scroll;
  margin-bottom: 25px;
}

#style-1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar {
    width: 3px;
    background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, .3);
    background-color: #807474;
}

.drag-move thead th {
  border-bottom: 1px solid #c2cfd6;
}

.drag-move tr:first-child th {
  top: -1px;
  border-top: none;
}

.ghost {
  background-color: #F3F4F6 !important;
  opacity: 1 !important;
  color: #807474;
}
</style>
